<template>
  <div>
    <page-title title="Pay for BidBond" />
    <div class="row row-sm">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <div id="company-payment">
              <div class="mpesa">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-center">
                      <img src="/m-pesa.png" alt="mpesa" height="150px">
                    </div>
                    <div class="manual">
                      <div class="alert alert-warning" v-if="charge.partial">
                        <p>You have only made a partial payment.</p>
                        <p class="mb-1">Expected: <small>KES</small> <strong>{{ $number.format(charge.original) }}</strong></p>
                        <p>Received: <small>KES</small> <strong>{{ $number.format(charge.paid) }}</strong></p>
                      </div>
                      <div class="col-md-6">
                        <label>Phone Number</label>
                        <input class="form-control" name="phone_number" v-model="phone_number" />
                      </div>
                      <div class="col-md-12">
                        <p>Press the button below to initiate an M-pesa Payment of Amount(KSH) &nbsp;<strong>{{ charge.total }}</strong> :</p>
                      </div>
                      <div class="alert alert-danger" v-if="payment_cancelled">You have cancelled your payment. Please try again</div>
                      <div class="alert alert-danger" v-if="payment_timed_out">Your payment has timed out. Please try again</div>

                    <button class="btn btn-primary" :disabled="stk_initiated" @click.prevent="makeMpesaPay()">Initiate Payment</button>

                      <div v-if="waiting_payment  && stk_initiated && ! payment_cancelled"  class="mt-5 alert alert-info alert-with-border">
                        <p>Waiting for Payment</p>
                        <div class="spinner-border"></div>
                      </div>
                      <div>
                        <modal :showModal="! waiting_payment && payment_has_been_received && ! payment_cancelled">
                          <template slot="header">
                            <h4 class="modal-title">Payment Received</h4>
                          </template>
                          <div class="alert alert-success">We have received your payment. Click below to continue.</div>
                          <router-link :to="{ name: 'bidbonds.show' }" class="btn btn-secondary btn-l"><i class="lni-arrow-right mr-3"></i> Proceed</router-link>
                        </modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
    this.waitForPayment()
    this.phone_number = this.$store.state.UserStore.user.phone_number
  },

  data() {
    return {
      charge: {},
      payment_not_received: false,
      partial_payment: false,
      waiting_payment: true,
      payment_has_been_received: false,
      stk_initiated: false,
      payment_cancelled: false,
      payment_timed_out: false,
      merchant_request_id: null,
      phone_number: null,
    };
  },

  methods: {
    waitForPayment() {
      setTimeout(() => {
        this.confirmPayment()
      }, 5000)
    },

    fetch() {
      let data = {
        secret: this.$route.params.id,
      };

      this.$axios
        .post("/api/v1/dashboard/bidbonds/get-payment", data)
        .then((response) => {
          if(response.data.paid) {
            this.$router.push({ name: 'bidbonds.show' })
          } else {
            this.charge = response.data.charge;
          }

        })
        .catch((error) => {
          this.$alert.httpError(error.response);
        });
    },

    makeMpesaPay(){

      let data = {
        amount: this.charge.total,
        unique_id: this.$route.params.id,
        phone_number:this.phone_number
      };

      
    this.stk_initiated = true
    this.payment_cancelled= false
    this.payment_timed_out = false
    this.payment_has_been_received = false
    this.$axios.post("/api/v1/dashboard/bidbonds/makepayment",data).then(response =>{
     

      if(response.data.ResponseCode == 0){
       
        this.waitForPayment()

        this.merchant_request_id = response.data.MerchantRequestID
        this.$toast.success("Sucessfully initiated payment. Please complete the payment on your phone.")
        this.stk_initiated = true
        return
      }else {
       
        this.$toast.error("Something went wrong. Please try again.")
        this.stk_initiated = false
      }

     
    }).catch(error =>{
      console.log(error)
      this.stk_initiated = false
      this.$toast.error("Something went wrong. Please try again")
    })

    },
    confirmPayment() {
      let data = {
        account: this.account,
        merchant_request_id: this.merchant_request_id
      };
      
      this.payment_not_received = false

      this.$axios.post("/api/v1/dashboard/bidbonds/confirm-payment", data).then(response => {
        let status = response.data

        // console.log(status)
        if(status == 1) {
          this.payment_has_been_received = true
          this.waiting_payment = false
          this.stk_initiated = false

          // setTimeout(() => {
          //   this.$router.push({ name: 'bidbonds.show' })
          // }, 5000)
        }else if (status == 3){

          this.payment_cancelled = true
          this.stk_initiated = false
          this.waiting_payment = false

          setTimeout(() => {

            this.resetPaymentCancelled();
            
          }, 5000); // 5000 milliseconds = 5 seconds
          

        }else if(status == 4){

          this.payment_timed_out = true
          this.stk_initiated = false
          this.waiting_payment = false

          setTimeout(() => {
            this.resetPaymentTimedOut();
          }, 5000); // 5000 milliseconds = 5 seconds

        }
        else {
          this.fetch()
          this.waitForPayment()
        }
      })
    },
    resetPaymentCancelled() {
      this.payment_cancelled = false;
      },
    
    // Method to reset payment_timed_out after 5 seconds
resetPaymentTimedOut() {
  this.payment_timed_out = false;
  },
  },

  computed: {
    account() {
      return this.$route.params.id;
    },

    mpesa_paybill() {
      return process.env.VUE_APP_MPESA_PAYBILL;
    },
  },
};
</script>